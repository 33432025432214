import React, { useCallback, useMemo, useState, useContext } from 'react';
import { useHistory } from 'react-router';
import {
  Box,
  Menu,
  Button,
  MenuItem,
  IconButton,
  Typography,
  UIContext,
  CircularProgress,
} from '@miyagami-com/lsx-ui-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { KeyboardArrowDownIcon, AccountCircleIcon } from '../Icons';
import { RootState } from '../../../../types';

import useMyAccountPath from '../../../common/hooks/useMyAccountPath';
import useBrandId from '../../../common/hooks/useBrandId';

import messages from './messages';
import useAuth from '../AuthContext/useAuth';
import clearFirestoreCache from '../../../common/clearFirestoreCache';
import getRoleDataByClaims from '../../../common/getRoleDataByClaims';
import useUserBalance from '../../../common/hooks/useUserBalance';

type HeaderMenuProps = {
  isMobile: boolean;
};

const HeaderMenu: React.FC<HeaderMenuProps> = ({ isMobile }) => {
  const history = useHistory();

  const { signOut } = useAuth();

  const myAccountPath = useMyAccountPath();

  const brandId = useBrandId();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const isOpen = Boolean(anchorEl);

  const { email, unParsedRoles } = useSelector(
    (state: RootState) => state.user,
  );

  const { uid: userId } = useSelector((state: RootState) => state.user);

  const { data: userBalanceResponse } = useUserBalance({ userId });

  const userRoleData = useMemo(() => {
    const roleData = getRoleDataByClaims({
      roles: unParsedRoles || [],
      brandId: brandId || '',
    });

    return roleData;
  }, [brandId, unParsedRoles]);

  const { setAlert } = useContext(UIContext);
  const intl = useIntl();

  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onClickMenu = (path: string) => {
    history.push(path);
    onClose();
  };

  const onLogout = useCallback(async () => {
    try {
      onClose();
      setIsLoggingOut(true);
      setAlert({
        show: true,
        severity: 'info',
        message: intl.formatMessage(messages.loggingOutAlert),
      });
      await signOut();
      clearFirestoreCache();
      setAlert({
        show: true,
        severity: 'success',
        message: intl.formatMessage(messages.logoutSuccess),
      });
      history.push('/login');
    } catch (error) {
      setAlert({
        show: true,
        severity: 'error',
        message: intl.formatMessage(messages.logoutError),
      });
    } finally {
      setIsLoggingOut(false);
    }
  }, [history, signOut, setAlert, intl, onClose]);

  return (
    <>
      {isMobile ? (
        <IconButton onClick={onClick} color="secondary" disabled={isLoggingOut}>
          {isLoggingOut ? (
            <CircularProgress size={24} sx={{ color: '#fff' }} />
          ) : (
            <AccountCircleIcon fontSize="large" />
          )}
        </IconButton>
      ) : (
        <Box display="flex" flexDirection="column" alignItems="flex-end">
          <Button
            id="header-button"
            onClick={onClick}
            variant="text"
            color="primary"
            endIcon={!isLoggingOut && <KeyboardArrowDownIcon />}
            disabled={isLoggingOut}
          >
            {isLoggingOut ? (
              <CircularProgress size={20} sx={{ color: '#fff' }} />
            ) : (
              email
            )}
          </Button>
          {userRoleData && (
            <Box pt={1} display={'flex'} flexDirection={'row'} gap={5}>
              <Box>
                <Typography
                  color="white"
                  fontWeight="bold"
                  variant="subtitle2"
                  lineHeight={1}
                >
                  Balance: {userBalanceResponse?.balance || 0}
                </Typography>
              </Box>
              <Box>
                <Typography
                  color="white"
                  fontWeight="bold"
                  variant="subtitle2"
                  lineHeight={1}
                >
                  {userRoleData.label}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      )}
      <Menu
        id="header-menu"
        aria-labelledby="header-button"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={onClose}
      >
        <MenuItem onClick={() => onClickMenu(myAccountPath)}>
          <FormattedMessage {...messages.myAccount} />
        </MenuItem>
        <MenuItem onClick={() => onClickMenu('/settings')}>
          <FormattedMessage {...messages.settings} />
        </MenuItem>
        <MenuItem onClick={onLogout} disabled={isLoggingOut}>
          <FormattedMessage {...messages.logout} />
        </MenuItem>
      </Menu>
    </>
  );
};

export default HeaderMenu;
