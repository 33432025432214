import { useContext, useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  TextField,
  Typography,
  UIContext,
} from '@miyagami-com/lsx-ui-components';
import { FormattedMessage, useIntl } from 'react-intl';
import DefaultLayout from '../Unknown/DefaultLayout';
import OverviewLayout from '../Unknown/OverviewLayout';
import MarketList from './MarketBetsList';
import messages from './messages';
import useGetOutcomes, { OutcomeBets } from './useGetOutcomes';
import LoadingBox from '../Unknown/LoadingBox';
import { useFirebaseApp } from 'reactfire';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { DEFAULT_REGION } from '../../common/constants';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import useUpdateBetsEventOpenDate from '../../common/hooks/useUpdateBetsEventOpenDate';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types';
import checkIsSystemAdmin from '../../common/checkIsSystemAdmin';

const MarketActionsPage = (): JSX.Element => {
  const path = [{ label: 'Market actions' }, { label: 'Market actions' }];

  const intl = useIntl();
  const { setAlert } = useContext(UIContext);

  const firebase = useFirebaseApp();
  const functions = getFunctions(firebase, DEFAULT_REGION);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [marketId, setMarketId] = useState('');
  const [rows, setRows] = useState<OutcomeBets[]>([]);
  const { data, isFetching, refetch } = useGetOutcomes(marketId);

  const updateBetsEventOpenDate = useUpdateBetsEventOpenDate();

  const { roles } = useSelector((root: RootState) => root.user);

  const isSystemAdmin = checkIsSystemAdmin({ roles });

  useEffect(() => {
    if (data && marketId) {
      setRows(data);
    } else {
      setRows([]);
    }
  }, [data, marketId]);

  const handleEventOpenDateUpdate = async (
    brandId: string,
    marketIdToUpdate: string,
    newDate: Date,
  ) => {
    await updateBetsEventOpenDate({
      brandId,
      marketId: marketIdToUpdate,
      eventOpenDate: newDate,
    });
    await refetch();
    return;
  };

  const handleResettle = async () => {
    const resettleBets = httpsCallable(
      functions,
      'back-exchangeBet-invokeResettleMarket',
    );

    try {
      setIsSubmitting(true);
      await resettleBets({ marketId });

      setAlert({
        show: true,
        severity: 'success',
        message: intl.formatMessage(messages.resettleSuccess),
      });
    } catch (error) {
      setAlert({
        show: true,
        severity: 'error',
        message: intl.formatMessage(messages.resettleError),
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <DefaultLayout path={path}>
      <OverviewLayout
        headerProps={{
          name: 'Market actions',
          isMarketActionsPage: true,
          marketId,
          setMarketId,
        }}
      >
        {isFetching ? <LoadingBox /> : <MarketList rows={rows} />}
        {!!rows.length && !isFetching && (
          <Box p={4}>
            <Box mb={4}>
              <Typography variant="h6" gutterBottom>
                <FormattedMessage {...messages.updateEventOpenDate} />
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  readOnly={!isSystemAdmin}
                  renderInput={(props) => <TextField size="small" {...props} />}
                  value={new Date(rows[0].event_open_date)}
                  onChange={() => {}}
                  onAccept={(newValue: Date | null) => {
                    if (newValue !== null) {
                      handleEventOpenDateUpdate(
                        rows[0].brand_id,
                        rows[0].market_id,
                        newValue,
                      );
                    }
                  }}
                />
              </LocalizationProvider>
            </Box>
            <LoadingButton
              loading={isSubmitting}
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleResettle}
            >
              <FormattedMessage {...messages.resetAll} />
            </LoadingButton>
          </Box>
        )}
      </OverviewLayout>
    </DefaultLayout>
  );
};

export default MarketActionsPage;
