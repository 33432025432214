import React, { useEffect, useState } from 'react';
import {
  InputAdornment,
  TextField,
  Box,
} from '@miyagami-com/lsx-ui-components';
import { GridRowData } from '@mui/x-data-grid';
import { useIntl } from 'react-intl';
import SearchIcon from '@mui/icons-material/Search';
import { useParams } from 'react-router';

import useDebounce from '../../../common/hooks/useDebounce';

import messages from './messages';
import useStyles from './useStyles';

export type TableSearchProps = {
  defaultRows: GridRowData[];
  setRows: CallableFunction;
  filteredKey?: string;
  withBrandFilter?: boolean;
};

function escapeRegExp(value: string): string {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

type QueryParams = {
  brandId?: string;
};

const TableSearch: React.FC<TableSearchProps> = ({
  defaultRows,
  setRows,
  filteredKey = 'brandId',
  withBrandFilter = false,
}) => {
  const { brandId }: QueryParams = useParams();

  type Row = Record<typeof filteredKey, keyof typeof defaultRows>;

  const intl = useIntl();
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedSearchTerm = useDebounce(searchValue, 500);

  useEffect(() => {
    const filterByBrand = (rowsList: Row[]) => {
      if (brandId && withBrandFilter) {
        setRows(
          rowsList.filter((row) => row[filteredKey] === brandId),
          debouncedSearchTerm,
        );
      } else {
        setRows(rowsList, debouncedSearchTerm);
      }
    };
    if (debouncedSearchTerm) {
      const searchRegex = new RegExp(escapeRegExp(debouncedSearchTerm), 'i');
      const filteredRows = defaultRows.filter((row: GridRowData) => {
        return Object.keys(row).some((field: string) => {
          const text = row[field];
          if (text) {
            return searchRegex.test(text);
          }
        });
      });
      filterByBrand(filteredRows as Row[]);
    } else {
      filterByBrand(defaultRows as Row[]);
    }
  }, [
    brandId,
    debouncedSearchTerm,
    defaultRows,
    setRows,
    filteredKey,
    withBrandFilter,
  ]);

  return (
    <TextField
      value={searchValue}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        setSearchValue(e.target.value)
      }
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Box color="text.secondary">
              <SearchIcon />
            </Box>
          </InputAdornment>
        ),
      }}
      classes={{ root: classes.input }}
      id="table-search"
      placeholder={intl.formatMessage(messages.search)}
      variant="standard"
      type="search"
    />
  );
};

export default TableSearch;
