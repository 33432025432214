import { useState, useEffect } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useFirebaseApp } from 'reactfire';
import { DEFAULT_REGION } from '../constants';

export const useGetDefaultStakeValues = (
  brandId: string,
): {
  defaultValues: number[];
  isLoading: boolean;
  error: Error | null;
} => {
  const [defaultValues, setDefaultValues] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const firebase = useFirebaseApp();

  useEffect(() => {
    const fetchDefaultValues = async () => {
      try {
        const functions = getFunctions(firebase, DEFAULT_REGION);
        const callGetDefaultStakeValues = httpsCallable(
          functions,
          'back-brand-callGetDefaultStakeValues',
        );

        const result = await callGetDefaultStakeValues({ brandId });
        setDefaultValues(result.data as number[]);
      } catch (err) {
        setError(
          err instanceof Error
            ? err
            : new Error('Failed to fetch default stake values'),
        );
      } finally {
        setIsLoading(false);
      }
    };

    fetchDefaultValues();

    return () => {};
  }, [brandId, firebase]);

  return { defaultValues, isLoading, error };
};
