import React from 'react';
import useAuth from '../AuthContext/useAuth';
import { useMetadataWatcher } from '../../../common/hooks/useMetadataWatcher';
import BackofficeUserBrandsWrapper from '../BackofficeUserBrandsWrapper';
import SystemAdminBrandsWrapper from '../SystemAdminBrandsWrapper';
import UserRoots from './UserRoots';
import IdleTimer from '../IdleTimer';
import checkIsSystemAdmin from '../../../common/checkIsSystemAdmin';
import { BackofficeUserRole } from '../../../../types/supabase';

interface AuthenticatedRootProps {
  roles: BackofficeUserRole[] | null;
}

const AuthenticatedRoot: React.FC<AuthenticatedRootProps> = ({ roles }) => {
  const { signOut } = useAuth();

  const isSystemAdmin = checkIsSystemAdmin({ roles });

  const handleForceLogout = () => {
    window.location.href = '/login';
    signOut();
  };

  useMetadataWatcher({ onForceLogout: handleForceLogout });

  if (isSystemAdmin) {
    return (
      <SystemAdminBrandsWrapper>
        <IdleTimer />
        <UserRoots />
      </SystemAdminBrandsWrapper>
    );
  }

  return (
    <BackofficeUserBrandsWrapper>
      <IdleTimer />
      <UserRoots />
    </BackofficeUserBrandsWrapper>
  );
};

export default AuthenticatedRoot;
