import { useAuth } from 'reactfire';
import DefaultLayout from '../Unknown/DefaultLayout';
import OverviewLayout from '../Unknown/OverviewLayout';
import DataGridTable from '../Unknown/DataGridTable';
import { DEFAULT_ROWS_PER_PAGE_OPTIONS } from '../../common/constants';
import { useState } from 'react';
import useGetFundPassingBets from '../../common/hooks/useGetFundPassingBets';
import BetSnapshotModal from './BetSnapshotModal';
import useColumns from './useColumns';
import useBrandId from '../../common/hooks/useBrandId';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

type BetTypeFilter = 'all' | 'pre-match' | 'live';

const RiskBetsPage = (): JSX.Element => {
  const path = [{ label: 'Risk bets' }, { label: 'Risk bets' }];

  const [pageSize, setPageSize] = useState(DEFAULT_ROWS_PER_PAGE_OPTIONS[5]);
  const [modalOpen, setModalOpen] = useState(false);
  const [betTypeFilter, setBetTypeFilter] = useState<BetTypeFilter>('all');

  const [betId, setBetId] = useState(null);
  const [betType, setBetType] = useState('');

  const [marketName, setMarketName] = useState('');
  const [selectionId, setSelectionId] = useState(0);
  const [selectionName, setSelectionName] = useState('');
  const [eventOpenDateTime, setEventOpenDateTime] = useState('');

  const auth = useAuth();
  const brandId = useBrandId();

  const { data: fundPassingData, isLoading } = useGetFundPassingBets({
    uid: auth.currentUser?.uid || '',
    brandId,
  });

  const rows = fundPassingData?.data || [];
  const filteredRows = rows.filter((row) => {
    if (betTypeFilter === 'all') return true;
    return betTypeFilter === 'live' ? row.is_in_play : !row.is_in_play;
  });

  const onPageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  const onCloseModal = () => {
    setModalOpen(false);
  };

  const columns = useColumns({
    setModalOpen,
    setBetId,
    setMarketName,
    setSelectionId,
    setSelectionName,
    setEventOpenDateTime,
    setBetType,
  });

  return (
    <DefaultLayout path={path}>
      <OverviewLayout
        headerProps={{
          name: 'Risk bets',
          defaultRows: filteredRows,
        }}
      >
        <FormControl sx={{ minWidth: 200, m: 2 }}>
          <InputLabel>Bet Type</InputLabel>
          <Select
            value={betTypeFilter}
            label="Bet Type"
            onChange={(e) => setBetTypeFilter(e.target.value as BetTypeFilter)}
          >
            <MenuItem value="all">All bets</MenuItem>
            <MenuItem value="pre-match">Pre-match bets</MenuItem>
            <MenuItem value="live">Live bets</MenuItem>
          </Select>
        </FormControl>
        <DataGridTable
          rows={filteredRows}
          columns={columns}
          tableKey="risk-bets"
          pageSize={pageSize}
          onPageSizeChange={onPageSizeChange}
          rowsPerPageOptions={DEFAULT_ROWS_PER_PAGE_OPTIONS}
          disableSelectionOnClick
          autoHeight
          rowHeight={35}
          headerHeight={35}
          loading={isLoading}
        />
        {betId && (
          <BetSnapshotModal
            isOpen={modalOpen}
            onClose={onCloseModal}
            betId={betId}
            marketName={marketName}
            selectionId={selectionId}
            selectionName={selectionName}
            eventOpenDateTime={eventOpenDateTime}
            betType={betType}
          />
        )}
      </OverviewLayout>
    </DefaultLayout>
  );
};

export default RiskBetsPage;
