import { useQuery, UseQueryResult } from 'react-query';
import useSupabase from './useSupabase';
import { useCallback } from 'react';

export interface TelegramSettings {
  id: string;
  chat_id: string;
  min_range: number;
  max_range: number;
  brand_id: string;
}

const useGetTelegramSettings = (
  brandId: string,
): UseQueryResult<TelegramSettings[], unknown> => {
  const supabase = useSupabase();

  const getSettings = useCallback(async () => {
    const { data, error } = await supabase
      .from('brand_telegram_settings')
      .select('id, chat_id, min_range, max_range, brand_id')
      .eq('brand_id', brandId)
      .neq('chat_id', '');

    if (error) {
      console.error('Error while fetching telegram settings', error);
      return [];
    }

    return data;
  }, [brandId, supabase]);

  const queryResult = useQuery(['telegramSettings', brandId], async () => {
    return getSettings();
  });

  return queryResult;
};

export default useGetTelegramSettings;
