import { Box, Typography, Divider } from '@miyagami-com/lsx-ui-components';
import { useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import FilterByBrand, { FilterByBrandProps } from '../FilterByBrand';
import { SelectProps } from '../Select';
import TableDefaultToolbar, {
  TableDefaultToolbarProps,
} from '../TableDefaultToolbar';
import DateRangePicker, { DateRangeProps } from '../DateRangePicker';
import messages from './messages';
import { Player } from '../../../../types/supabase';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../types';
import checkIsSystemAdmin from '../../../common/checkIsSystemAdmin';
import TESTusePlayers from '../../../common/hooks/usePlayers';
import ToggleMaintenanceMode from '../ToggleMaintenanceMode';
import SearchMarketId from '../../MarketActions/SearchMarketId';

export interface DatePeriodSelectProps extends SelectProps {
  minWidth?: number;
}

export type HeaderWithFilterProps = TableDefaultToolbarProps &
  FilterByBrandProps & {
    name: string | null;
    from?: string | null;
    to?: string | null;
    dateRangeProps?: DateRangeProps;
    datePeriodSelectProps?: DatePeriodSelectProps;
    isPlayerOverviewPage?: boolean;
    isMarketActionsPage?: boolean;
    brandId?: string;
    marketId?: string;
    setMarketId?: React.Dispatch<React.SetStateAction<string>>;
    renderCustomFilter?: () => React.ReactNode;
    isBrandOverviewPage?: boolean;
  };

interface ExtendedPlayer extends Player {
  userStats: {
    balance: number;
    exposure: number;
  }[];
}

const HeaderWithFilter: React.FC<HeaderWithFilterProps> = ({
  name,
  from,
  to,
  brands,
  dateRangeProps,
  datePeriodSelectProps,
  isPlayerOverviewPage = false,
  isBrandOverviewPage = false,
  isMarketActionsPage = false,
  brandId,
  marketId,
  setMarketId,
  renderCustomFilter,
  ...tableTolbarProps
}) => {
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));
  const isTablet = !useMediaQuery(theme.breakpoints.up('md')) && !isMobile;

  const [totalExposure, setTotalExposure] = useState<number>(0);
  const [totalBalance, setTotalBalance] = useState<number>(0);

  const { roles } = useSelector((root: RootState) => root.user);
  const isSystemAdmin = checkIsSystemAdmin({ roles });

  const parentPath = roles?.[0].backoffice_user_id;

  const { data: allPlayers, isLoading } = TESTusePlayers({
    brandId,
    page: 0,
    size: 0,
    parentPath: parentPath || '',
    isSystemAdmin,
    isFetchAllPlayers: true,
  });

  useEffect(() => {
    if (!isPlayerOverviewPage) return;

    if (!isLoading && allPlayers) {
      let totExposure = 0;
      let totBalance = 0;

      (allPlayers?.data as ExtendedPlayer[]).forEach(
        (player: ExtendedPlayer) => {
          if (player.status === 'active') {
            totExposure += player?.userStats[0]?.exposure || 0;
            totBalance += player?.userStats[0]?.balance || 0;
          }
        },
      );

      setTotalExposure(totExposure);
      setTotalBalance(totBalance);
    }
  }, [allPlayers, isLoading, isPlayerOverviewPage]);

  return (
    <>
      <Box
        p={3}
        display={isTablet || isMobile ? 'block' : 'flex'}
        justifyContent="space-between"
      >
        <Box pr={3} pb={isMobile ? 4 : undefined}>
          <Typography variant="h4">{name}</Typography>
          {from && to && (
            <Typography variant="h3" color="textSecondary" pt={0.5}>
              {/* <FormattedMessage {...messages.fromTo} values={{ from, to }} />
              <br /> */}
              <FormattedMessage {...messages.warning} values={{ from, to }} />
            </Typography>
          )}
        </Box>
        {isPlayerOverviewPage && (
          <Box>
            <Box display="flex">
              <Typography variant="h3" mr={1}>
                <FormattedMessage {...messages.globalExposure} />
              </Typography>
              <Typography>{totalExposure.toFixed(2)}</Typography>
            </Box>
            <Box display="flex">
              <Typography variant="h3" mr={1}>
                <FormattedMessage {...messages.globalBalance} />
              </Typography>
              <Typography>{totalBalance.toFixed(2)}</Typography>
            </Box>
          </Box>
        )}
        {isSystemAdmin && isBrandOverviewPage && (
          <Box display="flex" alignItems="center">
            <ToggleMaintenanceMode />
          </Box>
        )}
        {isMarketActionsPage && (
          <Box display="flex" alignItems="center">
            <SearchMarketId
              marketId={marketId || ''}
              setMarketId={setMarketId || (() => {})}
            />
          </Box>
        )}
        <Box
          display="flex"
          alignItems="center"
          flexWrap={isTablet || isMobile ? 'wrap' : 'nowrap'}
        >
          {dateRangeProps && (
            <Box pr={2}>
              <DateRangePicker {...dateRangeProps} />
            </Box>
          )}
          {brands && (
            <Box pr={2} py={{ xs: 2, md: 0 }}>
              <FilterByBrand brands={brands} />
            </Box>
          )}
          {renderCustomFilter?.()}
          <TableDefaultToolbar {...tableTolbarProps} />
        </Box>
      </Box>
      <Divider />
    </>
  );
};

export default HeaderWithFilter;
