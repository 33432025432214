import { defineMessages } from 'react-intl';

export default defineMessages({
  betId: {
    id: 'ExchangeBet.ExchangeBetExportList.betId',
    defaultMessage: 'Bet ID',
  },
  sportType: {
    id: 'ExchangeBet.ExchangeBetExportList.sportType',
    defaultMessage: 'Sport Type',
  },
  playerId: {
    id: 'ExchangeBet.ExchangeBetExportList.playerId',
    defaultMessage: 'Player ID',
  },
  playerEmail: {
    id: 'ExchangeBet.ExchangeBetExportList.playerEmail',
    defaultMessage: 'Player Email',
  },
  marketName: {
    id: 'ExchangeBet.ExchangeBetExportList.marketName',
    defaultMessage: 'Market name',
  },
  betStatus: {
    id: 'ExchangeBet.ExchangeBetExportList.betStatus',
    defaultMessage: 'Bet Status',
  },
  createdAt: {
    id: 'ExchangeBet.ExchangeBetExportList.createdAt',
    defaultMessage: 'Created At',
  },
  settledAt: {
    id: 'ExchangeBet.ExchangeBetExportList.settledAt',
    defaultMessage: 'Settled At',
  },
  totalSize: {
    id: 'ExchangeBet.ExchangeBetExportList.totalSize',
    defaultMessage: 'Total Size',
  },
  price: {
    id: 'ExchangeBet.ExchangeBetExportList.price',
    defaultMessage: 'Price',
  },
  matchedSize: {
    id: 'ExchangeBet.ExchangeBetExportList.matchedSize',
    defaultMessage: 'Matched Size',
  },
  eventName: {
    id: 'ExchangeBet.ExchangeBetExportList.eventName',
    defaultMessage: 'Event Name',
  },
  selectionName: {
    id: 'ExchangeBet.ExchangeBetExportList.selectionName',
    defaultMessage: 'Selection Name',
  },
  competitionName: {
    id: 'ExchangeBet.ExchangeBetExportList.competitionName',
    defaultMessage: 'Competition Name',
  },
  side: {
    id: 'ExchangeBet.ExchangeBetExportList.side',
    defaultMessage: 'Side',
  },
});
