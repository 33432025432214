import {
  GridEnrichedColDef,
  GridColumns,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
} from '@miyagami-com/lsx-ui-components';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import renderPriorityCell from './renderPriorityCell';
import renderPriorityEditCell from './renderPriorityEditCell';
import renderRiskLevelCell from './renderRiskLevelCell';
import renderRiskLevelEditCell from './renderRiskLevelEditCell';
import { NavigationNodeType } from '../../../../types';
import IsDisabledCell from '../DateRangeSettings/IsDisabledCell';

const defaultColumnParams: Partial<GridEnrichedColDef> = {
  editable: false,
  filterable: false,
  hideSortIcons: true,
  disableColumnMenu: true,
  align: 'left',
  headerAlign: 'left',
};

export interface SportLineRow {
  id: string;
  name: string;
  priority: number;
  riskLevel: string;
  isEnabled: boolean;
  path: string;
  type: NavigationNodeType;
  itemId: string;
  disabledBy?: string | null;
  playerId?: string | null;
  parentRow: SportLineRow | null;
}

const useColumns = (
  isSubmitting: boolean,
  hidePriority: boolean,
): GridColumns => {
  const intl = useIntl();

  const columns: GridColumns = useMemo(() => {
    return [
      {
        ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
        hide: true,
      },
      {
        field: 'id',
        hide: true,
      },
      {
        field: 'sport',
        headerName: intl.formatMessage(messages.sport),
        hide: true,
        flex: 1,
        ...defaultColumnParams,
      },
      {
        field: 'priority',
        headerName: intl.formatMessage(messages.priority),
        flex: 1,
        type: 'number',
        ...defaultColumnParams,
        editable: true,
        renderCell: renderPriorityCell,
        renderEditCell: renderPriorityEditCell,
        hide: hidePriority,
      },
      {
        field: 'riskLevel',
        headerName: intl.formatMessage(messages.riskLevel),
        flex: 1,
        ...defaultColumnParams,
        editable: true,
        renderCell: renderRiskLevelCell,
        renderEditCell: renderRiskLevelEditCell,
      },
      {
        field: 'isEnabled',
        headerName: intl.formatMessage(messages.enable),
        flex: 1,
        ...defaultColumnParams,
        renderCell: (params) => (
          <IsDisabledCell {...params} disabled={isSubmitting} />
        ),
        renderEditCell: (params) => (
          <IsDisabledCell {...params} disabled={isSubmitting} />
        ),
      },
    ];
  }, [intl, isSubmitting, hidePriority]);

  return columns;
};

export default useColumns;
