import { useCallback } from 'react';
import { useFirebaseApp } from 'reactfire';
import { GridRowData } from '@mui/x-data-grid';
import { useQuery } from 'react-query';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { DEFAULT_REGION } from '../constants';

interface UseFundPassingBetsParams {
  uid: string;
  brandId: string;
}

interface GetFundPassingBetsResult {
  data: {
    fundPassingBetsResponse: GridRowData[];
  };
}

const useGetFundPassingBets = (
  params: UseFundPassingBetsParams,
): typeof queryResult => {
  const firebase = useFirebaseApp();
  const functions = getFunctions(firebase, DEFAULT_REGION);

  const getFundPassingBetsFunction = httpsCallable<
    UseFundPassingBetsParams,
    GetFundPassingBetsResult
  >(functions, 'back-exchangeBet-callGetFundPassingBets');

  const getFundPassingBets = useCallback(async () => {
    if (!params.uid) {
      return { data: [], count: 0 };
    }

    const response = await getFundPassingBetsFunction(params);
    const data = response.data.data.fundPassingBetsResponse;
    const count = data.length;

    return { data, count };
  }, [getFundPassingBetsFunction, params]);

  const queryResult = useQuery<
    { data: GridRowData[]; count: number | null },
    Error
  >(['fundPassingBets', params.uid, params.brandId], getFundPassingBets, {
    keepPreviousData: false,
    onError: (error) => error,
    enabled: !!params.uid,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchInterval: false,
    retry: 2,
  });

  return queryResult;
};

export default useGetFundPassingBets;
