import React, { useContext } from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
  CollapseBox,
  UIContext,
} from '@miyagami-com/lsx-ui-components';
import { Form, Formik } from 'formik';
import { useIntl } from 'react-intl';
import DefaultStakeValuesForm from './DefaultStakeValuesForm';
import { useGetDefaultStakeValues } from '../../../common/hooks/useGetDefaultStakeValues';
import { useUpdateDefaultStakeValues } from '../../../common/hooks/useUpdateDefaultStakeValues';
import useBrandId from '../../../common/hooks/useBrandId';
import messages from './messages';

const DefaultStakeSettings = (): JSX.Element => {
  const brandId = useBrandId();
  const { defaultValues } = useGetDefaultStakeValues(brandId);
  const { updateDefaultStakeValues, isLoading } = useUpdateDefaultStakeValues();
  const { setAlert } = useContext(UIContext);
  const intl = useIntl();

  const handleUpdate = async (values: { stakeValues: number[] }) => {
    await updateDefaultStakeValues(brandId, values.stakeValues, setAlert);
  };

  return (
    <CollapseBox
      defaultValue={true}
      label={intl.formatMessage(messages.defaultStakeSettings)}
    >
      <Box p={3} bgcolor="background.paper">
        <Typography variant="h6" gutterBottom>
          {intl.formatMessage(messages.defaultStakes)}
        </Typography>
        <Box mb={3} />
        <Formik
          initialValues={{ stakeValues: defaultValues }}
          enableReinitialize
          onSubmit={handleUpdate}
        >
          {({ values, handleSubmit, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                {(values.stakeValues || []).map((value, index) => (
                  <DefaultStakeValuesForm
                    key={index}
                    index={index}
                    value={value}
                    handleChange={(idx, val) => {
                      const newValues = [...(values.stakeValues || [])];
                      newValues[idx] = val;
                      setFieldValue('stakeValues', newValues);
                    }}
                  />
                ))}
              </Grid>
              <Box mt={2}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                >
                  {intl.formatMessage(messages.save)}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </CollapseBox>
  );
};

export default DefaultStakeSettings;
