import { defineMessages } from 'react-intl';

export default defineMessages({
  successUpdate: {
    id: 'RiskBets.MaxOddsVolume.successUpdate',
    defaultMessage: 'Max odds volume updated successfully',
  },
  errorUpdate: {
    id: 'RiskBets.MaxOddsVolume.errorUpdate',
    defaultMessage: 'Failed to update max odds volume',
  },
  errorFetch: {
    id: 'RiskBets.MaxOddsVolume.errorFetch',
    defaultMessage: 'Failed to fetch max odds volume',
  },
  maxOddsVolumeLabel: {
    id: 'RiskBets.MaxOddsVolume.maxOddsVolumeLabel',
    defaultMessage: 'Maximum odds volume value',
  },
  valueValidation: {
    id: 'RiskBets.MaxOddsVolume.valueValidation',
    defaultMessage: 'Value must be greater than or equal to 1',
  },
  pathRiskBets: {
    id: 'RiskBets.MaxOddsVolume.pathRiskBets',
    defaultMessage: 'Risk bets',
  },
  pathMaxOddsVolume: {
    id: 'RiskBets.MaxOddsVolume.pathMaxOddsVolume',
    defaultMessage: 'Maximum odds volume',
  },
  pageTitle: {
    id: 'RiskBets.MaxOddsVolume.pageTitle',
    defaultMessage: 'Maximum odds volume settings',
  },
});
