import { Box } from '@miyagami-com/lsx-ui-components';
import { DataGridProps } from '@mui/x-data-grid';
import React from 'react';
import { DEFAULT_ROWS_PER_PAGE } from '../../../common/constants';
import DataGridTable from '../../Unknown/DataGridTable';
import useColumns from './useColumns';

interface EventOverviewListProps {
  dataGridParams: Omit<DataGridProps, 'columns'>;
  hasSearch?: boolean;
}

const EventOverviewList: React.FC<EventOverviewListProps> = (props) => {
  const { dataGridParams } = props;

  const columns = useColumns();

  return (
    <Box>
      <DataGridTable
        {...dataGridParams}
        tableKey={'event-overview'}
        rowsPerPageOptions={DEFAULT_ROWS_PER_PAGE}
        disableSelectionOnClick={true}
        columns={columns}
        autoHeight={true}
        rowHeight={35}
        headerHeight={35}
        pagination
        hideFooterRowCount
        hideFooterSelectedRowCount
      />
    </Box>
  );
};

export default EventOverviewList;
