import { useState } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useFirebaseApp } from 'reactfire';
import { DEFAULT_REGION } from '../constants';
import { UIContext } from '@miyagami-com/lsx-ui-components';

interface UpdateDefaultStakeValuesParams {
  brandId: string;
  stakeValues: number[];
}

interface UpdateDefaultStakeValuesReturn {
  updateDefaultStakeValues: (
    brandId: UpdateDefaultStakeValuesParams['brandId'],
    stakeValues: UpdateDefaultStakeValuesParams['stakeValues'],
    setAlert: Parameters<typeof UIContext.Provider>[0]['value']['setAlert'],
  ) => Promise<void>;
  isLoading: boolean;
}

export const useUpdateDefaultStakeValues =
  (): UpdateDefaultStakeValuesReturn => {
    const [isLoading, setIsLoading] = useState(false);
    const firebase = useFirebaseApp();

    const updateDefaultStakeValues = async (
      brandId: UpdateDefaultStakeValuesParams['brandId'],
      stakeValues: UpdateDefaultStakeValuesParams['stakeValues'],
      setAlert: Parameters<typeof UIContext.Provider>[0]['value']['setAlert'],
    ) => {
      setIsLoading(true);

      try {
        const functions = getFunctions(firebase, DEFAULT_REGION);
        const updateFunction = httpsCallable(
          functions,
          'back-brand-updateDefaultStakeValues',
        );
        await updateFunction({ brandId, defaultStakes: stakeValues });
        setAlert({
          show: true,
          severity: 'success',
          message: 'Stake values successfully updated!',
        });
      } catch (err) {
        setAlert({
          show: true,
          severity: 'error',
          message:
            err instanceof Error
              ? err.message
              : 'Failed to update default stake values',
        });
      } finally {
        setIsLoading(false);
      }
    };

    return {
      updateDefaultStakeValues,
      isLoading,
    };
  };
