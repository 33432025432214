import { defineMessages } from 'react-intl';

export default defineMessages({
  tableLabel: {
    id: 'User.UserLimitPage.tableLabel',
    defaultMessage: 'Limits',
  },
  updateLimitsSuccess: {
    id: 'User.UserLimitPage.updateLimitsSuccess',
    defaultMessage: 'Limit was successfully upgraded.',
  },
  maxAmountError: {
    id: 'User.UserLimitPage.maxAmountError',
    defaultMessage: 'Amount must be only positive number!',
  },
  updateLimitsError: {
    id: 'User.UserLimitPage.updateLimitsError',
    defaultMessage: 'An error occurred while updating limits!',
  },
  saveChanges: {
    id: 'UserLimitPage.saveChanges',
    defaultMessage: 'Save',
  },
});
