import { getFunctions, httpsCallable } from 'firebase/functions';
import { useFirebaseApp } from 'reactfire';
import { DEFAULT_REGION } from '../../common/constants';
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQuery,
} from 'react-query';

interface PlayerData {
  id: string;
  name: string;
  surname: string;
}

export interface BetData {
  id: string;
  size: number;
  price: number;
  side: string;
  selection_name: string;
  status: string;
  market_name: string;
  outcomeId: string;
}

export interface OutcomeBets {
  id: string;
  event_name: string;
  event_open_date: string;
  total_return: number;
  liability: number;
  total_bets: number;
  total_volume: number;
  brand_id: string;
  market_id: string;
  players: PlayerData;
  exchange_bets: BetData[];
}

const normaliseOutcomes = (outcomes: OutcomeBets[]) => {
  return outcomes
    .filter((outcome) => outcome.exchange_bets.length)
    .sort(
      (a, b) =>
        a.brand_id.localeCompare(b.brand_id) ||
        a.players.name.localeCompare(b.players.name),
    );
};

interface UseGetOutcomesResult {
  data: OutcomeBets[] | undefined;
  isFetching: boolean;
  error: unknown;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<OutcomeBets[], unknown>>;
}

const useGetOutcomes = (marketId: string): UseGetOutcomesResult => {
  const firebase = useFirebaseApp();
  const functions = getFunctions(firebase, DEFAULT_REGION);

  const getOutcomesByMarketId = httpsCallable<
    { marketId: string },
    OutcomeBets[]
  >(functions, 'back-market-callGetOutcomesByMarketId');

  const { data, isFetching, error, refetch } = useQuery(
    ['outcomes', marketId],
    async () => {
      const outcomesData = await getOutcomesByMarketId({
        marketId,
      });

      const outcomes = outcomesData.data;

      return normaliseOutcomes(outcomes);
    },
    {
      enabled: !!marketId,
    },
  );

  return { data, isFetching, error, refetch };
};

export default useGetOutcomes;
