import { FC, useCallback, useMemo, useState } from 'react';
import { GridColumns } from '@miyagami-com/lsx-ui-components';
import {
  DEFAULT_DATE_TIME_FORMAT,
  DEFAULT_ROWS_PER_PAGE_OPTIONS,
} from '../../../common/constants';
import DataGridTable from '../../Unknown/DataGridTable';
import { IBetRow } from './interfaces';
import ExchangeBetExportToolbar from './ExchangeBetExportToolbar';
import format from 'date-fns/format';
import { useIntl } from 'react-intl';
import messages from './messages';

const userPathsByType = {
  systemAdmins: 'system-admins',
  brandOwners: 'brand-owners',
  backofficeUsers: 'backoffice-users',
};

type Props = {
  type?: keyof typeof userPathsByType;
  isLoading?: boolean;
  betRows: IBetRow[];
  brandId?: string;
};

const ExchangeBetExportList: FC<Props> = ({
  type = 'backofficeUsers',
  brandId,
  isLoading = false,
  betRows,
}) => {
  const intl = useIntl();
  const formatDate = useCallback(
    (date: Date | null, dateFormat = DEFAULT_DATE_TIME_FORMAT) => {
      return date ? format(date, dateFormat) : '';
    },
    [],
  );
  const columns = useMemo<GridColumns<IBetRow>>(() => {
    return [
      {
        field: 'id',
        headerName: intl.formatMessage(messages.betId),
      },
      {
        field: 'player_id',
        headerName: intl.formatMessage(messages.playerId),
      },
      {
        field: 'player_email',
        headerName: intl.formatMessage(messages.playerEmail),
      },
      {
        field: 'created_at',
        headerName: intl.formatMessage(messages.createdAt),
        valueFormatter: ({ value }) => {
          return formatDate(value);
        },
      },
      {
        field: 'settled_at',
        headerName: intl.formatMessage(messages.settledAt),
        valueFormatter: ({ value }) => {
          return formatDate(value);
        },
      },
      {
        field: 'sport_type',
        headerName: intl.formatMessage(messages.sportType),
      },
      {
        field: 'competition_name',
        headerName: intl.formatMessage(messages.competitionName),
      },
      {
        field: 'event_name',
        headerName: intl.formatMessage(messages.eventName),
      },
      {
        field: 'market_name',
        headerName: intl.formatMessage(messages.marketName),
      },
      {
        field: 'selection_name',
        headerName: intl.formatMessage(messages.selectionName),
      },
      {
        field: 'side',
        headerName: intl.formatMessage(messages.side),
      },
      {
        field: 'status',
        headerName: intl.formatMessage(messages.betStatus),
        valueGetter: ({ row }) => {
          if (row.cancelled_size === row.size) {
            return 'cancelled';
          }
          return row.status;
        },
      },
      {
        field: 'price',
        headerName: intl.formatMessage(messages.price),
      },
      {
        field: 'size',
        headerName: intl.formatMessage(messages.totalSize),
      },
      {
        field: 'matched_size',
        headerName: intl.formatMessage(messages.matchedSize),
      },
    ];
  }, [formatDate, intl]);
  const [pageSize, setPageSize] = useState(DEFAULT_ROWS_PER_PAGE_OPTIONS[5]);
  const tableKey = userPathsByType[type];
  const betExportDate = useMemo(() => {
    if (betRows?.length) {
      return formatDate(new Date(), 'yyyyMMddHHmmss');
    }

    return null;
  }, [betRows, formatDate]);

  const onPageSizeChange = useCallback((newPageSize: number) => {
    setPageSize(newPageSize);
  }, []);

  return (
    <DataGridTable
      autoHeight
      tableKey={tableKey}
      loading={isLoading}
      columns={columns}
      pageSize={pageSize}
      onPageSizeChange={onPageSizeChange}
      rowsPerPageOptions={DEFAULT_ROWS_PER_PAGE_OPTIONS}
      sortModel={[{ field: 'created_at', sort: 'desc' }]}
      componentsProps={{
        toolbar: {
          csvOptions: {
            fileName: brandId
              ? `${brandId}_bet_export_${betExportDate}`
              : `bet_export_${betExportDate}`,
          },
          disabled: isLoading || !betRows?.length,
        },
      }}
      components={{
        Toolbar: ExchangeBetExportToolbar,
      }}
      rows={betRows || []}
    />
  );
};

export default ExchangeBetExportList;
