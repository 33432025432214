import { useEffect } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';
import { getAuth } from 'firebase/auth';

interface UseMetadataWatcherProps {
  onForceLogout: () => void;
}

export const useMetadataWatcher = ({
  onForceLogout,
}: UseMetadataWatcherProps): void => {
  useEffect(() => {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) {
      return;
    }

    const db = getDatabase();
    const metadataRef = ref(db, `metadata/${currentUser.uid}`);
    // eslint-disable-next-line prefer-const
    let intervalId: NodeJS.Timeout;
    const cleanupRef = { current: () => {} };

    function handleForceLogout() {
      if (intervalId) clearInterval(intervalId);
      cleanupRef.current();
      onForceLogout();
    }

    cleanupRef.current = onValue(metadataRef, (snapshot) => {
      const data = snapshot.val();

      if (!data?.revokeTime) {
        return;
      }

      const lastSignInTime =
        currentUser.metadata.lastSignInTime || new Date().toISOString();
      const tokenIssuedAt = new Date(lastSignInTime).getTime() / 1000;

      if (data.revokeTime > tokenIssuedAt) {
        handleForceLogout();
        return;
      }

      currentUser.getIdToken(true).catch(() => {
        handleForceLogout();
      });
    });

    intervalId = setInterval(() => {
      currentUser.getIdToken(true).catch(() => {
        handleForceLogout();
      });
    }, 5000);

    return () => {
      if (intervalId) clearInterval(intervalId);
      cleanupRef.current();
    };
  }, [onForceLogout]);
};
