import { defineMessages } from 'react-intl';

export default defineMessages({
  id: {
    id: 'Event.EventOverviewList.id',
    defaultMessage: 'ID',
  },
  actions: {
    id: 'Event.EventOverviewList.actions',
    defaultMessage: 'Actions',
  },
  viewEvent: {
    id: 'Event.EventOverviewList.viewEvent',
    defaultMessage: 'View',
  },
  status: {
    id: 'Event.EventOverviewList.status',
    defaultMessage: 'Status',
  },
  eventTypeName: {
    id: 'Event.EventOverviewList.eventTypeName',
    defaultMessage: 'Event type',
  },
  marketName: {
    id: 'Event.EventOverviewList.marketName',
    defaultMessage: 'Market',
  },
  eventName: {
    id: 'Event.EventOverviewList.eventName',
    defaultMessage: 'Event',
  },
  competitionName: {
    id: 'Event.EventOverviewList.competitionName',
    defaultMessage: 'League',
  },
  totalStake: {
    id: 'Event.EventOverviewList.totalStake',
    defaultMessage: 'Total stake',
  },
  maximumLoss: {
    id: 'Event.EventOverviewList.maximumLoss',
    defaultMessage: 'Maximum Loss',
  },
  maximumLossOutcome: {
    id: 'Event.EventOverviewList.maximumLossOutcome',
    defaultMessage: 'Maximum Loss Outcome',
  },
  maximumWin: {
    id: 'Event.EventOverviewList.maximumWin',
    defaultMessage: 'Maximum Win',
  },
  maximumWinOutcome: {
    id: 'Event.EventOverviewList.maximumWinOutcome',
    defaultMessage: 'Maximum Win Outcome',
  },
  openDate: {
    id: 'Event.EventOverviewList.openDate',
    defaultMessage: 'Date',
  },
  bestOutcome: {
    id: 'Event.EventOverviewList.bestOutcome',
    defaultMessage: 'Best Outcome',
  },
  statusComing: {
    id: 'Event.EventOverviewList.statusComing',
    defaultMessage: 'coming',
  },
  statusInPlay: {
    id: 'Event.EventOverviewList.statusInPlay',
    defaultMessage: 'in_play',
  },
});
