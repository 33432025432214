import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useAuth, useFirebaseApp } from 'reactfire';
import { DEFAULT_REGION } from '../../../common/constants';
import getPhoneNumber from '../../../common/getPhoneNumber';
import messages from './messages';
import clearFirestoreCache from '../../../common/clearFirestoreCache';
import {
  getMultiFactorResolver,
  IdTokenResult,
  multiFactor,
  MultiFactorError,
  MultiFactorInfo,
  MultiFactorResolver,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  TotpMultiFactorGenerator,
  TotpSecret,
  updatePassword,
  User,
} from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import useSignIn from '../../../common/hooks/useSignIn';
import { loginUserAction } from '../../../store/user';
import { useDispatch } from 'react-redux';
import useSupabaseContext from '../../../common/hooks/useSupabaseContext';

export interface PartialCredential {
  password: string;
}

export interface Credential extends PartialCredential {
  email: string;
}

export type OnSuccessSignUpParams = {
  tokenResult: IdTokenResult;
  user: User;
  credential: PartialCredential;
};

export type OnSuccessSignUpCallback = (
  params: OnSuccessSignUpParams,
) => void | Promise<void>;

export type OnSuccessSignInParams = {
  tokenResult: IdTokenResult;
  user: User;
  credential: Credential;
};

export type OnSuccessSignInCallback = (
  params: OnSuccessSignInParams,
) => void | Promise<void>;

export interface SignUpParams {
  token: string | null;
  password: string;
  onPhoneNumberCallback?: (phoneNumber: string) => void;
  onMfaCallback?: (tokenResult: IdTokenResult) => void;
  onSuccessSignUpCallback?: OnSuccessSignUpCallback;
}

export interface SubmitPhoneNumberParams {
  phoneNumber: string;
}

export interface SubmitMfaCodeParams {
  verificationId: string | null;
  verificationCode: string;
  onSuccessSignUpCallback?: OnSuccessSignUpCallback;
  onSuccessSingInCallback?: OnSuccessSignInCallback;
  password: string;
  type: 'signUp' | 'signIn';
}

export interface SingInParams {
  email: string;
  password: string;
  onSuccessSignInCallback?: OnSuccessSignInCallback;
}

const useFirebaseAuth = (): typeof authFunctions => {
  const intl = useIntl();

  const auth = useAuth();

  const dispatch = useDispatch();

  const firebaseApp = useFirebaseApp();

  const { setAuth: setSupabaseAuthToken } = useSupabaseContext();

  const serverSignIn = useSignIn();

  const [currentTotpSecret, setCurrentTotpSecret] = useState<TotpSecret | null>(
    null,
  );
  const [currentTotpUri, setCurrentTotpUri] = useState<string | null>(null);
  const [currentMfaResolver, setCurrentMfaResolver] = useState<{
    mfaResolver: MultiFactorResolver;
    factorId?: MultiFactorInfo['factorId'];
    factorUuid: string;
  } | null>(null);

  const functions = getFunctions(firebaseApp, DEFAULT_REGION);

  const resetTotpSecret = () => {
    setCurrentTotpSecret(null);
    setCurrentTotpUri(null);
    setCurrentMfaResolver(null);
  };

  const signInSupabase = async (idToken: string) => {
    const response = await serverSignIn({
      idToken,
      isFirstLogin: false,
    });

    if (!response) throw new Error('An error while trying auth');

    setSupabaseAuthToken(response.supabaseAccessToken);

    dispatch(loginUserAction(response.user));
  };

  const enrollTotp = async (code: string, displayName?: string) => {
    if (!auth.currentUser) throw new Error('User is not found');
    if (!currentTotpSecret) throw new Error('Totp secret is not found');

    const multiFactorAssertion =
      TotpMultiFactorGenerator.assertionForEnrollment(currentTotpSecret, code);

    await multiFactor(auth.currentUser).enroll(
      multiFactorAssertion,
      displayName,
    );

    resetTotpSecret();
  };

  const unenrollTotp = async () => {
    if (!auth.currentUser) throw new Error('User not found');

    const { enrolledFactors } = multiFactor(auth.currentUser);
    const totpFactor = enrolledFactors.find(
      (factor) => factor.factorId === 'totp',
    );

    if (!totpFactor) throw new Error('TOTP factor not found');

    await multiFactor(auth.currentUser).unenroll(totpFactor);
  };

  const getEnrolledFactors = () => {
    if (!auth.currentUser) return [];

    return multiFactor(auth.currentUser).enrolledFactors;
  };

  const requestTotpSecret = async (displayName?: string) => {
    if (!auth.currentUser || !auth.currentUser.email)
      throw new Error('User is not found');

    const multiFactorSession = await multiFactor(auth.currentUser).getSession();
    const totpSecret = await TotpMultiFactorGenerator.generateSecret(
      multiFactorSession,
    );
    const totpUri = totpSecret.generateQrCodeUrl(
      auth.currentUser.email,
      displayName,
    );

    setCurrentTotpSecret(totpSecret);
    setCurrentTotpUri(totpUri);
  };

  const resolveTotp = async (code: string) => {
    if (!currentMfaResolver) throw new Error('MFA resolver not found');

    const { mfaResolver, factorUuid } = currentMfaResolver;
    const multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(
      factorUuid,
      code,
    );
    const userCredential = await mfaResolver.resolveSignIn(
      multiFactorAssertion,
    );

    if (!userCredential.user) throw new Error('User not found');

    const idToken = await userCredential.user.getIdToken();
    await signInSupabase(idToken);
    resetTotpSecret();
  };

  const resolveMFA = async (
    error: MultiFactorError,
  ): Promise<typeof factorResolver> => {
    const mfaResolver = getMultiFactorResolver(auth, error);
    const [mfaFactor] = mfaResolver.hints;

    if (!mfaFactor) throw new Error('MFA not found');

    const factorResolver = {
      mfaResolver,
      factorId: mfaFactor.factorId,
      factorUuid: mfaFactor.uid,
    };
    setCurrentMfaResolver(factorResolver);

    return factorResolver;
  };

  const signIn = useCallback(
    async (params: SingInParams) => {
      const { email, password, onSuccessSignInCallback } = params;

      try {
        const { user } = await signInWithEmailAndPassword(
          auth,
          email,
          password,
        );

        if (!user) {
          throw new Error(intl.formatMessage(messages.authFailed));
        }

        const tokenResult = await user.getIdTokenResult();

        const credential: Credential = {
          password,
          email,
        };

        if (onSuccessSignInCallback) {
          await onSuccessSignInCallback({ tokenResult, user, credential });
        }
      } catch (error) {
        throw error;
      }
    },
    [auth, intl],
  );

  const signUp = useCallback(
    async (params: SignUpParams) => {
      const {
        token,
        password,
        onSuccessSignUpCallback,
        onMfaCallback,
        onPhoneNumberCallback,
      } = params;

      if (!token) throw new Error(intl.formatMessage(messages.tokenFailed));

      const { user } = await signInWithCustomToken(auth, token);

      if (!user) throw new Error(intl.formatMessage(messages.authFailed));

      const { phoneNumber } = user;

      if (!!phoneNumber && onPhoneNumberCallback) {
        onPhoneNumberCallback(phoneNumber);
      }

      await updatePassword(user, password);

      const tokenResult = await user.getIdTokenResult();

      const isMfaEnable = tokenResult.claims.mfa;

      if (isMfaEnable && onMfaCallback) {
        onMfaCallback(tokenResult);

        return;
      }

      if (onSuccessSignUpCallback) {
        const credential: PartialCredential = {
          password: password,
        };

        await onSuccessSignUpCallback({ tokenResult, user, credential });
      }
    },
    [auth, intl],
  );

  const submitPhoneNumber = useCallback(
    async (params: SubmitPhoneNumberParams) => {
      const { phoneNumber } = params;

      const updatePhoneNumber = httpsCallable(
        functions,
        'back-user-updatePhoneNumber',
      );

      const savePhoneNumber = getPhoneNumber({ phoneNumber });

      const user = auth.currentUser;

      if (!user) throw new Error(intl.formatMessage(messages.authFailed));

      await updatePhoneNumber({
        phoneNumber: savePhoneNumber,
        userId: user.uid,
      });

      return { user, phoneNumber: savePhoneNumber };
    },
    [auth.currentUser, functions, intl],
  );

  const submitMfaCode = useCallback(
    async (params: SubmitMfaCodeParams) => {
      const {
        verificationId,
        verificationCode,
        onSuccessSignUpCallback,
        password,
        type,
        onSuccessSingInCallback,
      } = params;

      const isSignUp = type === 'signUp';

      const callback:
        | OnSuccessSignInCallback
        | OnSuccessSignUpCallback
        | undefined = isSignUp
        ? onSuccessSignUpCallback
        : onSuccessSingInCallback;

      if (!verificationId)
        throw new Error(intl.formatMessage(messages.notFoundVerificationId));

      const credential = PhoneAuthProvider.credential(
        verificationId,
        verificationCode,
      );

      const multiFactorAssertion =
        PhoneMultiFactorGenerator.assertion(credential);

      const user = auth.currentUser;

      if (!user) throw new Error(intl.formatMessage(messages.authFailed));

      await multiFactor(user).enroll(multiFactorAssertion, 'phone number');

      const tokenResult = await user.getIdTokenResult();

      if (callback) {
        if (!user.email) throw new Error('Not found user email');

        const partialCredential: Credential = {
          password: password,
          email: user.email,
        };

        await callback({
          tokenResult,
          user,
          credential: partialCredential,
        });
      }
    },
    [auth.currentUser, intl],
  );

  const signOut = useCallback(async () => {
    const user = auth.currentUser;

    if (!user) throw new Error('You are not signed');

    await auth.signOut();
    clearFirestoreCache();
  }, [auth]);

  const authFunctions = {
    signIn,
    signUp,
    signOut,
    submitPhoneNumber,
    submitMfaCode,
    totpSecret: currentTotpSecret,
    totpUri: currentTotpUri,
    mfaResolver: currentMfaResolver,
    resolveMFA,
    setCurrentMfaResolver,
    requestTotpSecret,
    unenrollTotp,
    enrollTotp,
    resolveTotp,
    resetTotpSecret,
    getEnrolledFactors,
  };

  return authFunctions;
};

export default useFirebaseAuth;
