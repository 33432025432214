import { defineMessages } from 'react-intl';

export default defineMessages({
  stakeValue: {
    id: 'Brand.DefaultStakeValues.stakeValue',
    defaultMessage: 'Stake Value {index}',
  },
  saveSuccess: {
    id: 'Brand.DefaultStakeValues.saveSuccess',
    defaultMessage: 'Stake values successfully saved!',
  },
  saveError: {
    id: 'Brand.DefaultStakeValues.saveError',
    defaultMessage: 'An error occurred while saving stake values.',
  },
  defaultStakeSettings: {
    id: 'Brand.DefaultStakeValues.defaultStakeSettings',
    defaultMessage: 'Default Stake Settings',
  },
  defaultStakes: {
    id: 'Brand.DefaultStakeValues.defaultStakes',
    defaultMessage: 'Default Stakes',
  },
  save: {
    id: 'Brand.DefaultStakeValues.save',
    defaultMessage: 'Save',
  },
});
