import React, { useCallback, useEffect, useState } from 'react';
import { BoxProps, TableCell, TableFooter, TableRow } from '@mui/material';
import {
  Box,
  SimpleDataGridPremium,
  DataGridPremiumProps,
  Divider,
  useGridApiRef,
} from '@miyagami-com/lsx-ui-components';
import { WithoutDisplayNumberPage } from '@miyagami-com/lsx-ui-components/dist/components/SimpleDataGridPremium';
import {
  GridColumnResizeParams,
  GridSortModel,
} from '@mui/x-data-grid-premium';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE } from '../../../common/constants';
import messages from '../../Player/PlayerOverviewPage/messages';
import { FormattedMessage } from 'react-intl';
import calculateTotalsPerPage from '../../Player/PlayerOverviewPage/calculateTotalsPerPage';

type ColumnWidths = { [field: string]: number };

type DataGridTableProps = DataGridPremiumProps &
  WithoutDisplayNumberPage & {
    tableKey?: string;
    withoutPadding?: boolean;
    boxProps?: BoxProps;
  };

const DataGridTable: React.FC<DataGridTableProps> = (props) => {
  const apiRef = useGridApiRef();
  const [selectedRow, setSelectedRow] = useState<string | null>(null);

  const {
    pageSize: defaultPageSize,
    onPageSizeChange: defaultOnPageSizeChange,
    columns: unorderedColumns,
    tableKey,
    withoutPadding,
    boxProps,
    ...other
  } = props;

  const [helperPageSize, setHelperPageSize] = useState<number>(
    defaultPageSize || 5,
  );

  const [customColumns, setCustomColumns] = useState(unorderedColumns);

  const onChangeColumnResize = useCallback(
    (params: GridColumnResizeParams) => {
      const { colDef, width } = params;

      const field = colDef.field;

      const currentColumnWidths: ColumnWidths = JSON.parse(
        localStorage.getItem(tableKey || '') || '{}',
      );
      currentColumnWidths[field] = width;

      localStorage.setItem(tableKey || '', JSON.stringify(currentColumnWidths));
    },
    [tableKey],
  );

  useEffect(() => {
    const savedColumnWidths: ColumnWidths = JSON.parse(
      localStorage.getItem(tableKey || '') || '{}',
    );

    const updatedColumns = unorderedColumns.map((col) => {
      const savedWidth = savedColumnWidths[col.field];
      if (savedWidth) {
        return { ...col, width: savedWidth, flex: undefined };
      }
      return col;
    });

    setCustomColumns(updatedColumns);
  }, [tableKey, unorderedColumns]);

  const pageSize = defaultPageSize ? defaultPageSize : helperPageSize;

  const helperPageSizeChange = useCallback(
    (size: number) => setHelperPageSize(size),
    [setHelperPageSize],
  );

  const onPageSizeChange = defaultOnPageSizeChange
    ? defaultOnPageSizeChange
    : helperPageSizeChange;

  // persist sorted columns
  const initialSortModel = JSON.parse(
    localStorage.getItem(`${tableKey}-sortModel`) || '[]',
  );

  const [sortModel, setSortModel] = useState<GridSortModel>(initialSortModel);

  const onSortModelChange = useCallback(
    (newSortModel: GridSortModel) => {
      setSortModel(newSortModel);
      localStorage.setItem(
        `${tableKey}-sortModel`,
        JSON.stringify(newSortModel),
      );
    },
    [tableKey],
  );

  // persist pinned columns
  const initialPinnedColumns = JSON.parse(
    localStorage.getItem(`${tableKey}-pinnedColumns`) || '[]',
  );
  const [pinnedColumns, setPinnedColumns] = useState(initialPinnedColumns);

  const onPinnedColumnsChange = useCallback(
    (newPinnedColumns) => {
      setPinnedColumns(newPinnedColumns);
      localStorage.setItem(
        `${tableKey}-pinnedColumns`,
        JSON.stringify(newPinnedColumns),
      );
    },
    [tableKey],
  );

  const [totPageExposure, setTotPageExposure] = useState<string>('0');
  const [totPageBalance, setTotPageBalance] = useState<string>('0');

  const isPlayerOverviewPage = /player-overview(\/?)$/.test(
    window.location.href,
  );

  useEffect(() => {
    if (!isPlayerOverviewPage) return;

    const { totalExposure, totalBalance } = calculateTotalsPerPage({
      rows: props.rows,
      page: props.page || DEFAULT_PAGE,
      pageSize: props.pageSize || DEFAULT_PAGE_SIZE,
    });

    setTotPageExposure(totalExposure);
    setTotPageBalance(totalBalance);
  }, [props.rows, props.page, props.pageSize, isPlayerOverviewPage]);

  return (
    <>
      <Box p={withoutPadding ? 0 : 3} {...boxProps}>
        <SimpleDataGridPremium
          pagination
          apiRef={apiRef}
          columns={customColumns}
          pageSize={pageSize}
          onColumnResize={onChangeColumnResize}
          onPageSizeChange={onPageSizeChange}
          onSortModelChange={onSortModelChange}
          sortModel={sortModel}
          onPinnedColumnsChange={onPinnedColumnsChange}
          pinnedColumns={pinnedColumns}
          onRowClick={(params) => {
            const clickedId = params.id as string;
            setSelectedRow((prevSelected) =>
              prevSelected === clickedId ? null : clickedId,
            );
          }}
          getRowClassName={(params) =>
            params.id === selectedRow ? 'selected-row' : ''
          }
          sx={{
            '& .MuiDataGrid-row': {
              cursor: 'pointer',
            },
            '& .MuiDataGrid-row:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
            '& .selected-row': {
              backgroundColor: '#f5f5f5 !important',
              borderLeft: '4px solid #757575',
              fontWeight: 'bold',
            },
            '& .selected-row:hover': {
              backgroundColor: '#eeeeee !important',
            },
          }}
          {...other}
        />
        {isPlayerOverviewPage && (
          <TableFooter>
            <TableRow>
              <TableCell>
                <FormattedMessage
                  {...messages.totalExposure}
                  values={{ totExposure: totPageExposure }}
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  {...messages.totalBalance}
                  values={{ totBalance: totPageBalance }}
                />{' '}
              </TableCell>
            </TableRow>
          </TableFooter>
        )}
      </Box>
      <Divider />
    </>
  );
};

export default DataGridTable;
