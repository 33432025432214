import { defineMessages } from 'react-intl';

export default defineMessages({
  settings: {
    id: 'Unknown.Header.settings',
    defaultMessage: 'Settings',
  },
  myAccount: {
    id: 'Unknown.Header.myAccount',
    defaultMessage: 'My account',
  },
  logout: {
    id: 'Unknown.Header.logout',
    defaultMessage: 'Logout',
  },
  loggingOut: {
    id: 'Unknown.Header.loggingOut',
    defaultMessage: 'Logging out...',
  },
  loggingOutAlert: {
    id: 'Unknown.Header.loggingOutAlert',
    defaultMessage: 'Logging out of your account...',
  },
  lsxTech: {
    id: 'app.components.Unknown.Header.lsxTech',
    defaultMessage: 'LSX Tech',
  },
  logoutSuccess: {
    id: 'app.components.Unknown.Header.logoutSuccess',
    defaultMessage: 'Successfully logged out',
  },
  logoutError: {
    id: 'app.components.Unknown.Header.logoutError',
    defaultMessage: 'Error logging out. Please try again.',
  },
});
