import { Button } from '@miyagami-com/lsx-ui-components';
import {
  GridColumns,
  GridRenderCellParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import format from 'date-fns/format';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import {
  // DEFAULT_AMOUNT,
  // DEFAULT_CURRENCY,
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIME_FORMAT,
} from '../../../common/constants';
import messages from './messages';
import { NormalizedBet } from './useNormalizeBets';
import useExtendedExchangeBetStatusMap, {
  ExchangeBetStatus,
} from '../../../common/hooks/useExtendedExchangeBetStatusMap';

interface UseColumnsParams {
  brandId: string;
}

const propertiesColumn = {
  editable: false,
  filterable: false,
  minWidth: 50,
  flex: 0.4,
};

const useColumns = (props: UseColumnsParams): GridColumns<NormalizedBet> => {
  const { brandId } = props;

  const history = useHistory();

  const intl = useIntl();

  const exchangeBetStatusMap = useExtendedExchangeBetStatusMap();

  const onPushToPlayerPage = useCallback(
    (id: string) => {
      history.push(`/b/${brandId}/player-overview/${id}`);
    },
    [brandId, history],
  );

  const columns: GridColumns<NormalizedBet> = [
    {
      ...propertiesColumn,
      field: 'id',
      hide: true,
      headerName: intl.formatMessage(messages.id),
    },
    {
      ...propertiesColumn,
      field: 'date',
      headerName: intl.formatMessage(messages.date),
      valueFormatter: (params: GridValueFormatterParams<Date>) => {
        const { value } = params;

        if (!value) return null;

        return format(value, DEFAULT_DATE_FORMAT);
      },
    },
    {
      ...propertiesColumn,
      field: 'time',
      headerName: intl.formatMessage(messages.time),
      valueFormatter: (params: GridValueFormatterParams<Date>) => {
        const { value } = params;

        if (!value) return null;

        return format(value, DEFAULT_TIME_FORMAT);
      },
    },
    {
      ...propertiesColumn,
      field: 'stake',
      headerName: intl.formatMessage(messages.stake),
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        const { value } = params;

        if (!value && value !== 0) {
          return null;
        }

        const decimalPlaces = (value.toString().split('.')[1] || '').length;
        return decimalPlaces > 2 ? value.toFixed(2) : value;
      },
    },
    {
      ...propertiesColumn,
      field: 'odds',
      headerName: intl.formatMessage(messages.odds),
    },
    {
      ...propertiesColumn,
      field: 'side',
      headerName: intl.formatMessage(messages.side),
    },
    {
      ...propertiesColumn,
      field: 'selectionName',
      headerName: intl.formatMessage(messages.selection),
    },
    {
      ...propertiesColumn,
      field: 'marketName',
      headerName: intl.formatMessage(messages.market),
    },
    {
      ...propertiesColumn,
      field: 'status',
      headerName: intl.formatMessage(messages.status),
      valueFormatter: (params: GridValueFormatterParams<ExchangeBetStatus>) => {
        const { value } = params;

        if (!value) return null;

        return exchangeBetStatusMap?.[value]?.label;
      },
    },
    {
      ...propertiesColumn,
      field: 'playerName',
      groupable: false,
      headerName: intl.formatMessage(messages.player),
      flex: 0.5,
      renderCell: (params: GridRenderCellParams) => {
        const { value, row } = params;

        if (!value) return null;

        const { playerId } = row;

        return (
          <Button color="inherit" onClick={() => onPushToPlayerPage(playerId)}>
            {value}
          </Button>
        );
      },
    },
  ];

  return columns;
};

export default useColumns;
