import React, { useState, useContext, useMemo } from 'react';
import {
  CollapseBox,
  Grid,
  LoadingButton,
  Typography,
  UIContext,
  Divider,
  TextField,
} from '@miyagami-com/lsx-ui-components';
import { Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFirebaseApp } from 'reactfire';
import { useQueryClient } from 'react-query';
import { getFunctions, httpsCallable } from 'firebase/functions';
import Select from '../../Unknown/Select';
import { updateBrandAgentsStatus } from '../../../store/globalData';
import { DEFAULT_REGION, RISK_LEVELS } from '../../../common/constants';
import {
  BackOfficeUserStatus,
  ExtendedBackOfficeUser,
  ExtendedPlayer,
} from '../../../../types';
import useUserStatuses from '../../../common/hooks/useUserStatuses';
import useCheckBrandIsSuspend from '../../../common/hooks/useCheckBrandIsSuspend';
import {
  playerValidationSchema,
  backofficeUserValidationSchema,
} from './validationSchema';
import messages from './messages';
import getUserRiskLevel from '../../../common/user/getUserRiskLevel';

type RiskLevelsKeys = keyof typeof RISK_LEVELS;
type FormKeys = 'brandId' | 'status' | 'riskLevel';

interface FormValues {
  brandId?: string;
  status: BackOfficeUserStatus;
  riskLevel?: RiskLevelsKeys;
}

interface UserStatusTranslations {
  label: string;
}

interface UserStatusProps {
  brandId: string;
  user: ExtendedBackOfficeUser | ExtendedPlayer;
  translations: UserStatusTranslations;
  isDisabled: boolean;
  type: 'player' | 'backofficeUser';
}

interface DownlineUser {
  id: string;
  brand_id?: string;
  role_id?: string;
  player_detail?: string;
  username?: string;
  name?: string;
  backoffice_user_detail?: string;
  parent_id?: string;
}

interface GetDownlineResponse {
  data: {
    flatDownline: DownlineUser[];
  };
}

interface UpdateUserStatusParams {
  brandId: string;
  status: BackOfficeUserStatus;
  userId: string;
  role: 'player' | 'backofficeUser';
  riskLevel?: RiskLevelsKeys;
}

interface UpdateUserStatusResponse {
  success: boolean;
  tokensRevoked?: boolean;
}

const riskLevelMenuItems = Object.keys(RISK_LEVELS).map((value) => ({
  value,
  label: RISK_LEVELS[value as RiskLevelsKeys].label,
}));

const getUserStatus = (
  status?: BackOfficeUserStatus | null,
  isBrandSuspended?: boolean,
): BackOfficeUserStatus => {
  if (isBrandSuspended) return 'suspend';
  if (!status) return 'inactive';
  return status;
};

const UserStatus: React.FC<UserStatusProps> = ({
  brandId,
  user,
  translations: { label: pageLabel },
  isDisabled,
  type,
}) => {
  const isBrandSuspended = useCheckBrandIsSuspend();
  const queryClient = useQueryClient();
  const isPlayer = type === 'player';
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const intl = useIntl();
  const userStatuses = useUserStatuses();
  const dispatch = useDispatch();
  const firebase = useFirebaseApp();
  const functions = getFunctions(firebase, DEFAULT_REGION);
  const { setAlert } = useContext(UIContext);

  const initialValues: FormValues = useMemo(
    () => ({
      brandId,
      status: getUserStatus(
        user?.status as BackOfficeUserStatus | null,
        isBrandSuspended,
      ),
      riskLevel: isPlayer
        ? (getUserRiskLevel({ user })?.riskLevel as RiskLevelsKeys | undefined)
        : undefined,
    }),
    [brandId, isBrandSuspended, isPlayer, user],
  );

  const userStatusMenuItems = useMemo(
    () =>
      Object.keys(userStatuses).map((value) => ({
        value,
        label: userStatuses[value as BackOfficeUserStatus].label,
        hidden: userStatuses[value as BackOfficeUserStatus].hidden,
      })),
    [userStatuses],
  );

  const updateDownlineUsers = async (
    values: FormValues,
    downlineUsers: DownlineUser[],
  ) => {
    const updateUserStatus = httpsCallable(
      functions,
      'back-user-updateUserStatus',
    );

    for (const downlineUser of downlineUsers) {
      try {
        const role = downlineUser.player_detail ? 'player' : 'backofficeUser';
        await updateUserStatus({
          brandId,
          status: values.status,
          userId: downlineUser.id,
          role,
          riskLevel: values.riskLevel,
        });
      } catch (updateError) {
        continue;
      }
    }
  };

  const onSubmit = async (values: FormValues) => {
    setIsLoading(true);
    const updateUserStatus = httpsCallable<
      UpdateUserStatusParams,
      UpdateUserStatusResponse
    >(functions, 'back-user-updateUserStatus');

    try {
      await updateUserStatus({
        status: values.status,
        riskLevel: values.riskLevel,
        userId: user.id,
        role: type,
        brandId,
      });

      setAlert({
        show: true,
        severity: 'success',
        message: intl.formatMessage(messages.successChangeStatus),
      });

      if (values.status !== 'active') {
        try {
          const getDownline = httpsCallable<
            { brandId: string; userId: string },
            GetDownlineResponse['data']
          >(functions, 'back-brand-callGetDownline');

          const downlineResponse = await getDownline({
            brandId,
            userId: user.id,
          });

          if (downlineResponse?.data?.flatDownline) {
            await updateDownlineUsers(
              values,
              downlineResponse.data.flatDownline,
            );
          }
        } catch (downlineError) {}
      }

      dispatch(
        updateBrandAgentsStatus({
          agentId: user.id,
          brandId,
          status: values.status,
        }),
      );
    } catch (error) {
      setAlert({
        show: true,
        severity: 'error',
        message: intl.formatMessage(messages.errorChangeStatus),
      });
    } finally {
      setIsLoading(false);
      const queryKey = isPlayer ? 'player' : 'backofficeUser';
      queryClient.refetchQueries([queryKey, user?.id]);
      if (!isPlayer) {
        queryClient.refetchQueries(['agents', brandId]);
      }
    }
  };

  return (
    <CollapseBox
      label={intl.formatMessage(messages.collapseLabel)}
      defaultValue
    >
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={
          type === 'player'
            ? playerValidationSchema
            : backofficeUserValidationSchema
        }
        enableReinitialize
      >
        {({ handleChange, handleBlur, errors, values, touched }) => {
          const error = (name: FormKeys) =>
            Boolean(errors[name] && touched[name]);

          const getFieldProps = (name: FormKeys): typeof fieldProps => {
            const fieldProps = {
              name,
              fullWidth: true,
              onChange: handleChange,
              onBlur: handleBlur,
              error: error(name),
              value: values[name],
              disabled: isDisabled,
            };

            return fieldProps;
          };

          return (
            <Form>
              <Grid container>
                <Grid container paddingX={4} spacing={4}>
                  <Grid item xs={12} marginTop={4}>
                    <Typography variant="h6">{pageLabel}</Typography>
                  </Grid>
                  <Grid item xs={12} md={type === 'player' ? 4 : 6}>
                    <TextField
                      {...getFieldProps('brandId')}
                      label={intl.formatMessage(messages.brand)}
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={12} md={type === 'player' ? 4 : 6}>
                    <Select
                      labelId="status"
                      {...getFieldProps('status')}
                      label={intl.formatMessage(messages.status)}
                      menuItems={userStatusMenuItems}
                    />
                  </Grid>
                  {type === 'player' && (
                    <Grid item xs={12} md={4}>
                      <Select
                        labelId="riskLevel"
                        {...getFieldProps('riskLevel')}
                        label={intl.formatMessage(messages.riskLevel)}
                        menuItems={riskLevelMenuItems}
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid paddingTop={4} item xs={12}>
                  <Divider />
                </Grid>
                <Grid item container padding={4}>
                  <Grid item xs={12}>
                    <LoadingButton
                      disabled={isDisabled}
                      loading={isLoading}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      <FormattedMessage {...messages.saveButton} />
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </CollapseBox>
  );
};

export default UserStatus;
