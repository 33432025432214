import { DataGridProps } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../../common/constants';
import useEventOverviewBets, {
  MarketExposure,
} from '../../../common/hooks/useEventOverviewBets';
import OverviewLayout from '../../Unknown/OverviewLayout';
import EventOverviewList from '../EventOverviewList';
import messages from './messages';
import normalizeEventOverviewRows from './normalizeEventOverviewRows';

interface EventOverviewProps {
  brandId: string;
  userId: string;
  isAdmin: boolean;
}

const EventOverview: React.FC<EventOverviewProps> = (props) => {
  const { userId } = props;
  const intl = useIntl();

  const [page, setPage] = useState<number>(DEFAULT_PAGE);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);

  const [isSearchActive, setIsSearchActive] = useState<boolean>(false);
  const [originalData, setOriginalData] = useState<MarketExposure[]>([]);
  const [displayRows, setDisplayRows] = useState<MarketExposure[]>([]);

  const { data: apiData, isLoading } = useEventOverviewBets({
    brandId: userId,
  });

  useEffect(() => {
    if (apiData) {
      setOriginalData(apiData);
      setDisplayRows(apiData);
      setIsSearchActive(false);
    }
  }, [apiData]);

  const onPageChange = useCallback((newPage: number) => {
    setPage(newPage);
  }, []);

  const onPageSizeChange = useCallback((newPageSize: number) => {
    setPageSize(newPageSize);
  }, []);

  const handleSetRows = useCallback(
    (filteredRows: MarketExposure[], searchTerm?: string) => {
      setDisplayRows(filteredRows);

      const hasSearchTerm = searchTerm && searchTerm.length > 0;
      const hasFilteredResults = originalData.length !== filteredRows.length;
      setIsSearchActive(hasSearchTerm || hasFilteredResults);
    },
    [originalData],
  );

  const normalizedRows = useMemo(() => {
    return normalizeEventOverviewRows({ betEvents: displayRows });
  }, [displayRows]);

  const dataGridProps = useMemo<Omit<DataGridProps, 'columns'>>(
    () => ({
      page,
      pageSize,
      rows: normalizedRows,
      loading: isLoading,
      onPageChange,
      onPageSizeChange,
    }),
    [isLoading, normalizedRows, onPageChange, onPageSizeChange, page, pageSize],
  );

  return (
    <OverviewLayout
      headerProps={{
        name: intl.formatMessage(messages.eventOverview),
        defaultRows: originalData,
        setRows: handleSetRows,
      }}
    >
      <EventOverviewList
        dataGridParams={dataGridProps}
        hasSearch={isSearchActive}
      />
    </OverviewLayout>
  );
};

export default EventOverview;
