import React, { useMemo } from 'react';
import { Redirect, Route, Switch } from 'react-router';

import HomePage from '../../Home/HomePage';
import BrandDetailPage from '../../Brand/BrandDetailsPage';
import BackofficeUserAccountPage from '../../BackofficeUser/BackofficeUserAccountPage';
import BackofficeUserOverviewPage from '../../BackofficeUser/BackofficeUserOverviewPage';
import BrandOverviewPage from '../../Brand/BrandOverviewPage';
import PerformanceOverviewPage from '../../PerformanceOverview/PerformanceOverviewPage';
import WhiteListPage from '../../WhiteList/WhiteListPage';
import LogOverviewPage from '../../Log/LogOverviewPage';
import WidgetOverviewPage from '../../Widget/WidgetOverviewPage';
import PlayerOverviewPage from '../../Player/PlayerOverviewPage';
import ThemeSettingsPage from '../../Theme/ThemeSettingsPage';
import UserManualRegistration from '../../User/UserManualRegistration';
import WidgetDetailsPage from '../../Widget/WidgetDetailsPage';
import AgentDetailPage from '../../Agent/AgentDetailPage';
import PlayerDetailPage from '../../Player/PlayerDetailPage';
import AgentOverviewPage from '../../Agent/AgentOverviewPage';
import ExchangeSettingsPage from '../../Exchange/ExchangeSettingsPage';
import UserPermissionEditPage from '../../UserPermission/UserPermissionEditPage';
import ExchangeBetPage from '../../ExchangeBet/ExchangeBetPage';
import ExchangeBetDetailPage from '../../ExchangeBet/ExchangeBetDetailPage';
import UserListByRolePage from '../../User/UserListByRolePage';
import LanguageListPage from '../../Language/LanguageListPage';
import useMyAccountPath from '../../../common/hooks/useMyAccountPath';
import LanguageTranslationsPage from '../../Language/LanguageTranslationsPage';
import {
  PL_EVENT_HISTORY_BASE_URL,
  PL_REPORT_BASE_URL,
  PL_REPORT_DETAILED_BASE_URL,
} from '../../../common/constants';
import ProfitLossReportPage from '../../ProfitLoss/ProfitLossReportPage';
import ProfitLossDetailedPage from '../../ProfitLoss/ProfitLossDetailedPage';
import PlayerDuplicatesPage from '../../Player/PlayerDuplicatesPage';
import EventOverviewPage from '../../Event/EventOverviewPage';
import EventDetailPage from '../../Event/EventDetailPage';
import useBackOfficeUserType from './useBackOfficeUserType';
import RiskBetsPage from '../../RiskBets';
import PastEventOverviewPage from '../../Event/PastEventOverviewPage';
import ExchangeBetExportPage from '../../ExchangeBet/ExchangeBetExportPage';
import ProfitLossEventHistoryPage from '../../ProfitLoss/ProfitLossEventHistoryPage';
import MarketActionsPage from '../../MarketActions/MarketActionsPage';
import MaxOddsVolume from '../../RiskBets/MaxOddsVolume';

const UserRoots: React.FC = () => {
  const myAccountPath = useMyAccountPath();
  const backOfficeUserType = useBackOfficeUserType();

  const userRoutes = useMemo(() => {
    const routes = [
      {
        path: `/brand-overview`,
        exact: true,
        component: <BrandOverviewPage />,
      },
      {
        path: `/brand-overview/:brandId`,
        exact: true,
        component: <BrandDetailPage />,
      },
      {
        path: `/brand-owners`,
        exact: true,
        component: <UserListByRolePage type="brandOwners" />,
      },
      {
        path: `/b/:brandId/brand-owners/:userId/:userTab?`,
        exact: true,
        component: <BackofficeUserAccountPage type="brandOwners" />,
      },
      {
        path: `/brand-owners/:userId/:userTab?`,
        exact: true,
        component: <BackofficeUserAccountPage type="brandOwners" />,
      },
      {
        path: `/system-admins`,
        exact: true,
        component: <UserListByRolePage type="systemAdmins" />,
      },
      {
        path: `/system-admins/:userId`,
        exact: true,
        component: <BackofficeUserAccountPage type="systemAdmins" />,
      },
      {
        path: `/b/:brandId/system-logs`,
        exact: true,
        component: <LogOverviewPage />,
      },
      {
        path: `/b/:brandId/performance`,
        exact: true,
        component: <PerformanceOverviewPage />,
      },
      {
        path: `/b/:brandId/backoffice-users`,
        exact: true,
        component: <BackofficeUserOverviewPage />,
      },
      {
        path: `/b/:brandId/backoffice-users/:userId`,
        exact: true,
        component: <BackofficeUserAccountPage type="backofficeUsers" />,
      },
      {
        path: `/backoffice-users/:userId`,
        exact: true,
        component: <BackofficeUserAccountPage type="backofficeUsers" />,
      },
      {
        path: `/b/:brandId/backoffice-users/:userId/permissions`,
        exact: true,
        component: <UserPermissionEditPage />,
      },
      {
        path: `/b/:brandId/security`,
        exact: true,
        component: <WhiteListPage />,
      },
      {
        path: `/b/:brandId/theme-settings`,
        exact: true,
        component: <ThemeSettingsPage />,
      },
      {
        path: `/b/:brandId/widgets`,
        exact: true,
        component: <WidgetOverviewPage />,
      },
      {
        path: `/b/:brandId/widgets/:widgetId`,
        exact: true,
        component: <WidgetDetailsPage />,
      },
      {
        path: `/b/:brandId/manual-registration`,
        exact: true,
        component: <UserManualRegistration />,
      },
      {
        path: `/b/:brandId/agent-overview`,
        exact: true,
        component: <AgentOverviewPage />,
      },
      {
        path: `/b/:brandId/agent-overview/:userId/:userTab?/:exchangeTab?`,
        exact: true,
        component: <AgentDetailPage />,
      },
      {
        path: `/b/:brandId/player-overview`,
        exact: true,
        component: <PlayerOverviewPage />,
      },
      {
        path: `/b/:brandId/duplicate-players`,
        exact: true,
        component: <PlayerDuplicatesPage />,
      },
      {
        path: `/b/:brandId/player-overview/:userId/:userTab?/:exchangeTab?`,
        exact: true,
        component: <PlayerDetailPage />,
      },
      {
        path: `/b/:brandId/exchange-settings`,
        exact: true,
        component: <ExchangeSettingsPage />,
      },
      {
        path: `/b/:brandId/exchange-settings/:tab`,
        exact: true,
        component: <ExchangeSettingsPage />,
      },
      {
        path: '/risk-bets',
        exact: true,
        component: <RiskBetsPage />,
      },
      {
        path: '/risk-bets/max-odds-volume',
        exact: true,
        component: <MaxOddsVolume />,
      },
      {
        path: `/`,
        exact: true,
        component: <HomePage />,
      },
      {
        path: '/b/:brandId/betting-overview/:betId',
        exact: true,
        component: <ExchangeBetDetailPage />,
      },
      {
        path: '/b/:brandId/betting-overview',
        exact: true,
        component: <ExchangeBetPage />,
      },
      {
        path: myAccountPath,
        exact: true,
        component: <BackofficeUserAccountPage type={backOfficeUserType} />,
      },
      {
        path: `${myAccountPath}/:userTab?`,
        exact: true,
        component: <BackofficeUserAccountPage type={backOfficeUserType} />,
      },
      {
        path: `/internalization/:languageId`,
        exact: true,
        component: <LanguageTranslationsPage />,
      },
      {
        path: `/internalization`,
        exact: true,
        component: <LanguageListPage />,
      },
      {
        path: `/b/:brandId/bet-export`,
        component: <ExchangeBetExportPage />,
      },
      {
        path: `/b/:brandId/${PL_REPORT_BASE_URL}/:reportLevel/:agentId`,
        exact: true,
        component: <ProfitLossReportPage />,
      },
      {
        path: `/b/:brandId/${PL_REPORT_BASE_URL}/:reportLevel`,
        exact: true,
        component: <ProfitLossReportPage />,
      },
      {
        path: `/${PL_REPORT_BASE_URL}/:reportLevel`,
        exact: true,
        component: <ProfitLossReportPage />,
      },
      /// TEST LEVELS ///
      {
        path: `/b/:brandId/${PL_EVENT_HISTORY_BASE_URL}/:reportLevel/:agentId`,
        exact: true,
        component: <ProfitLossEventHistoryPage />,
      },
      {
        path: `/b/:brandId/${PL_EVENT_HISTORY_BASE_URL}/:reportLevel`,
        exact: true,
        component: <ProfitLossEventHistoryPage />,
      },
      {
        path: `/${PL_EVENT_HISTORY_BASE_URL}/:reportLevel`,
        exact: true,
        component: <ProfitLossEventHistoryPage />,
      },
      /// TEST LEV END ///
      {
        path: `/b/:brandId/${PL_REPORT_DETAILED_BASE_URL}/:reportLevel/:agentId`,
        exact: true,
        component: <ProfitLossDetailedPage />,
      },
      {
        path: `/b/:brandId/${PL_REPORT_DETAILED_BASE_URL}/:reportLevel`,
        exact: true,
        component: <ProfitLossDetailedPage />,
      },
      {
        path: `/${PL_REPORT_DETAILED_BASE_URL}/:reportLevel`,
        exact: true,
        component: <ProfitLossDetailedPage />,
      },
      {
        path: `/b/:brandId/event-overview`,
        exact: true,
        component: <EventOverviewPage />,
      },
      {
        path: `/b/:brandId/past-events`,
        exact: true,
        component: <PastEventOverviewPage />,
      },
      {
        path: `/b/:brandId/event-overview/:eventId`,
        exact: true,
        component: <EventDetailPage />,
      },
      {
        path: `/market-actions`,
        exact: true,
        component: <MarketActionsPage />,
      },
    ];

    return routes;
  }, [backOfficeUserType, myAccountPath]);

  return (
    <Switch>
      {userRoutes.map(({ component, ...routeParams }) => {
        return (
          <Route key={routeParams.path} {...routeParams}>
            {component}
          </Route>
        );
      })}
      <Redirect to="/" />
    </Switch>
  );
};

export default UserRoots;
