import React from 'react';
import { Grid, TextField } from '@miyagami-com/lsx-ui-components';
import { useIntl } from 'react-intl';
import messages from './messages';

interface StakeValuesFormProps {
  index: number;
  value: number;
  handleChange: (index: number, value: number) => void;
}

const DefaultStakeValuesForm = ({
  index,
  value,
  handleChange,
}: StakeValuesFormProps): JSX.Element => {
  const intl = useIntl();

  return (
    <Grid item xs={12} md={6}>
      <TextField
        label={intl.formatMessage(messages.stakeValue, { index: index + 1 })}
        value={value}
        onChange={(e) => {
          const num = Number(e.target.value);
          handleChange(index, Number.isNaN(num) ? 0 : num);
        }}
        variant="outlined"
        fullWidth
      />
    </Grid>
  );
};

export default DefaultStakeValuesForm;
