import { useState, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  Card,
  Grid,
  Typography,
  TextField,
  LoadingButton,
  Box,
  UIContext,
} from '@miyagami-com/lsx-ui-components';
import { getFunctions } from 'firebase/functions';
import { httpsCallable } from 'firebase/functions';
import { useIntl } from 'react-intl';
import { DEFAULT_REGION, SYSTEM_ADMIN_ROLE } from '../../../common/constants';
import firebaseApp from '../../../common/firebaseApp';
import DefaultLayout from '../../Unknown/DefaultLayout';
import OverviewLayout from '../../Unknown/OverviewLayout';
import messages from './messages';
import { RootState } from '../../../../types';

const MaxOddsVolume = (): JSX.Element => {
  const [maxOddsVolume, setMaxOddsVolume] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(true);
  const { setAlert } = useContext(UIContext);
  const intl = useIntl();
  const { unParsedRoles } = useSelector((root: RootState) => root.user);

  useEffect(() => {
    const fetchMaxOddsVolume = async () => {
      try {
        setFetchLoading(true);
        const functions = getFunctions(firebaseApp, DEFAULT_REGION);
        const getMaxOddsVolume = httpsCallable<void, number>(
          functions,
          'back-exchangeBet-getMaxOddsVolume',
        );
        const result = await getMaxOddsVolume();
        setMaxOddsVolume(result.data);
      } catch (error) {
        console.error('Failed to fetch max odds volume', error);
        setAlert({
          show: true,
          severity: 'error',
          message: intl.formatMessage(messages.errorFetch),
        });
      } finally {
        setFetchLoading(false);
      }
    };

    fetchMaxOddsVolume();
  }, [setAlert, intl]);

  const handleUpdateMaxOddsVolume = async () => {
    if (maxOddsVolume === null) return;

    try {
      setLoading(true);
      const functions = getFunctions(firebaseApp, DEFAULT_REGION);
      const updateMaxOddsVolume = httpsCallable(
        functions,
        'back-exchangeBet-updateMaxOddsVolume',
      );
      await updateMaxOddsVolume({ maxOddsVolume });
      setAlert({
        show: true,
        severity: 'success',
        message: intl.formatMessage(messages.successUpdate),
      });
    } catch (error) {
      setAlert({
        show: true,
        severity: 'error',
        message: intl.formatMessage(messages.errorUpdate),
      });
      console.error('Failed to update max odds volume', error);
    } finally {
      setLoading(false);
    }
  };

  if (!unParsedRoles?.includes(SYSTEM_ADMIN_ROLE)) {
    return <Redirect to="/" />;
  }

  const path = [
    { label: intl.formatMessage(messages.pathRiskBets) },
    { label: intl.formatMessage(messages.pathMaxOddsVolume) },
  ];

  return (
    <DefaultLayout path={path}>
      <OverviewLayout
        headerProps={{
          name: intl.formatMessage(messages.pageTitle),
        }}
      >
        <Card>
          <Box p={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Box>
                  <Typography variant="subtitle1" gutterBottom>
                    {intl.formatMessage(messages.maxOddsVolumeLabel)}
                  </Typography>
                  <TextField
                    type="number"
                    value={maxOddsVolume ?? ''}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value === '') {
                        setMaxOddsVolume(null);
                        return;
                      }
                      if (!value.startsWith('0') && Number(value) >= 1) {
                        setMaxOddsVolume(Number(value));
                      }
                    }}
                    fullWidth
                    variant="outlined"
                    inputProps={{ min: 1 }}
                    disabled={fetchLoading}
                    error={maxOddsVolume !== null && maxOddsVolume < 1}
                    helperText={
                      maxOddsVolume !== null && maxOddsVolume < 1
                        ? intl.formatMessage(messages.valueValidation)
                        : ''
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box mt={2}>
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    onClick={handleUpdateMaxOddsVolume}
                    loading={loading}
                    disabled={fetchLoading || maxOddsVolume === null}
                  >
                    Save
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </OverviewLayout>
    </DefaultLayout>
  );
};

export default MaxOddsVolume;
