import { useCallback, useContext } from 'react';
import { UseQueryResult, useQuery } from 'react-query';
import { BetEventStatus } from './useBetEventStatusMap';
import { ExchangeBetSide } from '../../../types';
import { UIContext } from '@miyagami-com/lsx-ui-components';
import { useFirebaseApp } from 'reactfire';
import { DEFAULT_REGION } from '../constants';
import { getFunctions, httpsCallable } from 'firebase/functions';

interface UseEventOverviewBetsParams {
  brandId: string;
}

export type EventExchangeBet = {
  id: string;
  side: ExchangeBetSide;
  price: number;
  size: number;
  selection_id: string;
};

export type BetEvent = {
  id: string;
  event_name: string;
  competition_name: string | null;
  event_type_name: string;
  open_date: string;
  total_stake: number;
  exchange_bets: EventExchangeBet[];
  status: string;
};

export interface ExtendedBetEvent extends BetEvent {
  status: BetEventStatus;
  exposure?: number;
  maximumWin?: number;
  marketName?: string;
  maxValueOutcome?: string;
  minValueOutcome?: string;
}

export type EventOverview = {
  data: BetEvent[];
  count: number | null;
};

export interface MarketExposure {
  id: string;
  exposure: number;
  maximumWin: number;
  marketName: string;
  eventName: string;
  eventId: string;
  size: number;
  price: number;
  side: string;
  selectionName: string;
  eventOpenDate: string;
  maxValueOutcome?: string;
  minValueOutcome?: string;
}

const useEventOverviewBets = (
  params: UseEventOverviewBetsParams,
): UseQueryResult<MarketExposure[] | null> => {
  const { setAlert } = useContext(UIContext);

  const firebase = useFirebaseApp();

  const functions = getFunctions(firebase, DEFAULT_REGION);

  const getUserMarketExposures = httpsCallable(
    functions,
    'back-exchangeBetNetwork-getUserMarketExposures',
  );

  const fetchUserMarketExposures = useCallback(async () => {
    try {
      const { data } = await getUserMarketExposures(params);
      return data as MarketExposure[];

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setAlert({
        show: true,
        severity: 'error',
        message: error?.message,
      });
      return [];
    }
  }, [getUserMarketExposures, params, setAlert]);

  const queryResult = useQuery(['userMarketExposures'], () => {
    return fetchUserMarketExposures();
  });

  return queryResult;
};

export default useEventOverviewBets;
